import { render, staticRenderFns } from "./ItemPlannerItem.vue?vue&type=template&id=1db5f452&scoped=true&"
import script from "./ItemPlannerItem.vue?vue&type=script&lang=js&"
export * from "./ItemPlannerItem.vue?vue&type=script&lang=js&"
import style0 from "./ItemPlannerItem.vue?vue&type=style&index=0&id=1db5f452&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db5f452",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.limburg/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow})
    

export default component.exports